import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { Helmet } from 'react-helmet';

export const query = graphql`
  query {
    file(relativePath: { eq: "PDFTron.svg" }) {
      publicURL
    }
  }
`;

const PDFTronPage = () => {
  const data = useStaticQuery(query);
  const { publicURL } = data.file;
  const businessCards = `
    <svg xmlns="http://www.w3.org/2000/svg" width="1000" height="800" version="1.1">
    <!-- Define the drop shadow filter -->
    <filter id="drop-shadow" height="150%">
        <feGaussianBlur in="SourceAlpha" stdDeviation="5"/>
        <feOffset dx="0" dy="5" result="offsetblur"/>
        <feFlood flood-color="rgba(0,0,0,0.5)"/>
        <feComposite in2="offsetblur" operator="in"/>
        <feMerge>
        <feMergeNode/>
        <feMergeNode in="SourceGraphic"/>
        </feMerge>
    </filter>

    <!-- Define the dimensions and positions for the card pile -->
    <rect x="50" y="50" width="700" height="700" fill="none"/>

    <!-- Card 1 -->
    <g transform="translate(100, 100) rotate(-10, 200, 250)">
        <image href="${publicURL}" width="200" height="300" filter="url(#drop-shadow)" />
    </g>

    <!-- Card 2 -->
    <g transform="translate(250, 100) rotate(5, 350, 250)">
        <image href="${publicURL}" width="200" height="300" filter="url(#drop-shadow)" />
    </g>

    <!-- Card 3 -->
    <g transform="translate(400, 100) rotate(15, 500, 250)">
        <image href="${publicURL}" width="200" height="300" filter="url(#drop-shadow)" />
    </g>

    <!-- Card 4 -->
    <g transform="translate(550, 100) rotate(-5, 650, 250)">
        <image href="${publicURL}" width="200" height="300" filter="url(#drop-shadow)" />
    </g>

    <!-- Card 5 -->
    <g transform="translate(200, 250) rotate(8, 300, 350)">
        <image href="${publicURL}" width="200" height="300" filter="url(#drop-shadow)" />
    </g>

    <!-- Card 6 -->
    <g transform="translate(350, 250) rotate(20, 450, 350)">
        <image href="${publicURL}" width="200" height="300" filter="url(#drop-shadow)" />
    </g>

    <!-- Card 7 -->
    <g transform="translate(500, 250) rotate(-12, 600, 350)">
        <image href="${publicURL}" width="200" height="300" filter="url(#drop-shadow)" />
    </g>

    <!-- Card 8 -->
    <g transform="translate(300, 400) rotate(7, 400, 500)">
        <image href="${publicURL}" width="200" height="300" filter="url(#drop-shadow)" />
    </g>

    </svg>

  `;
  return (
    
    <div>
      <Helmet>
        <title>PDFTron</title>
      </Helmet>
      <div dangerouslySetInnerHTML={{ __html: businessCards }} />
    </div>
  );
};

export default PDFTronPage;
